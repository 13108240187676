<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 用户列表 -->
        <div class="container">
            <div class="action">
                <el-button type="primary" @click="add()">新增奖品</el-button>
                <el-button type="danger" @click="clear()">清空奖品</el-button>
                <el-button type="info" @click="stateMany(1)">下线</el-button>
                <el-button type="warning" @click="stateMany(2)">上线</el-button>
                <el-button style="display:inline-block;margin-left:10px" type="primary" @click="exportFile">导出</el-button>
            </div>
            <el-table @selection-change="handleSelectionChange" v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="奖品ID" prop="goods_id" align="center" width="120"></el-table-column>
                <el-table-column label="排序" prop="r" align="center" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.r" placeholder="排序" type="number" @blur="editR(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="单次兑换赠送数量" prop="exchange_give_count" align="center" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.exchange_give_count" placeholder="赠送数量" type="number" @blur="editR(scope.row)"></el-input>
                    </template>
                </el-table-column>
              <el-table-column label="总兑换量" prop="sales" align="center" width="120"></el-table-column>
                <el-table-column label="价值" prop="sales" align="center" width="120"></el-table-column>
              <el-table-column label="当前库存" prop="stock" align="center" width="120"></el-table-column>
              <!-- (上次修改sku库存时的总数量)-->
              <el-table-column label="奖品名称" prop="goods.title" align="center"></el-table-column>
              <el-table-column label="奖品图片" prop="goods.thumb" align="center" width="120">
                    <template slot-scope="scope">
                        <el-image style="height:23px;width:30px;" :class="`column${scope.$index}`" @click="showBigImg(scope.$index)" :src="scope.row.goods.thumb" :preview-src-list="[scope.row.goods.thumb]">
                            <div slot="error" class="image-slot">
                                <el-image fit="cover" :src="defaultImg"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="品牌" prop="goods.brand.name" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-delete" @click="remove(scope.$index)">删除</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线</el-button>
                        <el-button v-if="scope.row.state === 1 || scope.row.state === 0" type="text" class="green" @click="state(scope.row, 2)">上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 删除 -->
        <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
            <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="removeVisible = false">取 消</el-button>
                <el-button type="primary" @click="deletePro">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 清空奖品 -->
        <el-dialog title="是否清空数据" :visible.sync="clearVisible" width="300px" center>
            <div class="del-dialog-cnt">清后数据无法恢复，是否确认清空？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clearVisible = false">取 消</el-button>
                <el-button type="primary" @click="clearPro">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 选择奖品对话框 -->
        <Addproduct :addVisible="addVisible" :title="title" :form="form" :goodsList="[]" @toggleAdd="toggleAdd" @getAddGood="getAddGood"></Addproduct>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Addproduct from '@/components/Addproduct';
import {request} from '@/common/request';
import loadEvents from '@/utils/loading'

let loads = new loadEvents();
export default {
    name: 'Users',
    components: {
        Breadcrumb,
        Pagination,
        Addproduct
    },
    data() {
        return {
            defaultImg: require('../../assets/img/zhan.jpeg'),
            addVisible: false,
            title: '',
            header: {},
            loading: false,
            clearVisible: false,
            gridData: [],
            dialogTableVisible: false,
            removeindex: 0,
            removeVisible: false,
            queryParams: {
                activity_id: '',
                name: '',
                page: 1,
                pagesize: 10,
                platform: 'admin'
            },
            form: {},
            pageInfo: {},
            radio: '1',
            imageUrl: '',
            menuList: ['营销', '签到活动奖品'],
            value: '',
            restaurants: [],
            input: '',
            text: '',
            selectProductList: []
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id !== this.id && to.path === "/activity-SignActivityGoodsList") {
                    this.queryParams.activity_id = to.query.id;
                    this.loadData();
                }
                //深度监听，同时也可监听到param参数变化
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        // 导出
        exportFile() {
            request.get('/site/export', { source_id: this.queryParams.activity_id, ty: 3 }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('导出成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //奖品列表
        loadData() {
            this.loading = true
            request.get('/activity/sign/activity/goods/list', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.loading = false
                    this.pageInfo = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        handleSelectionChange(val) {
            this.selectProductList = val;
        },
        // 表格导入
        beforeAvatarUpload(file) {
            let fileName = file.name
            let pos = fileName.lastIndexOf('.')
            let lastName = fileName.substring(pos, fileName.length)
            if (lastName.toLowerCase() !== '.xlsx') {
                this.$message.error('不支持此文件格式!');
            }
            loads.open(`body`, '导入中');
        },
        handleAvatarSuccess(res, file) {
            loads.close();
            if (res.code == 1) {
                this.loadData();
            } else {
                this.$message.error(res.msg);
            }
        },

        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        remove(index) {
            this.removeVisible = true;
            this.removeindex = index;
        },

        editSkuState(index, state) {
            this.gridData[index].state = state;
        },
        editR(item) {
            let ids = item.id;
            let r = item.r;
            let data ={
              r:r,
              ids:ids,
              stock: item.stock,
              exchange_give_count: item.exchange_give_count,

            }
            request.post('/activity/sign/activity/goods/edit', data).then(ret => {
                if (ret.code === 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
            console.log(item);
        },
        //新增奖品
        add() {
            this.addVisible = true;

        },
        clear() {
            this.clearVisible = true;
        },
        toggleAdd(val) {
            this.addVisible = val
        },

        // 清空奖品
        clearPro() {
            this.loading = true
            request.post('/activity/sign/activity/goods/remove/all', { activity_id: this.queryParams.activity_id }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false
                    this.clearVisible = false;
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        // 删除奖品
        deletePro() {
            let index = this.removeindex;
            let activity_id = this.pageInfo.list[index]['activity_id'];
            let goods_id = this.pageInfo.list[index]['goods_id'];
            request.post('/activity/sign/activity/goods/remove/one', { activity_id: activity_id, goods_id: goods_id }).then(ret => {
                if (ret.code == 1) {
                    this.removeVisible = false;
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 单个修改状态
        state(item, state) {
            let ids = item.id;
            request.post('/activity/sign/activity/goods/edit', { ids, state }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 批量修改状态
        stateMany(state) {
            let arr = [];
            let selectProductList = this.selectProductList;
            if (selectProductList.length) {
                console.log(111111)
                this.loading = true
                selectProductList.map((v) => {
                    arr.push(v.id);
                })
                let ids = arr.join(",");
                request.post('/activity/sign/activity/goods/edit', { ids, state }).then(ret => {
                    if (ret.code == 1) {
                        this.loading = false
                        this.$message.success('操作成功');
                        this.loadData();
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            } else {
                this.$message.warning('请选择奖品')
            }

        },
        // 添加奖品
        getAddGood(val) {
            this.loading = true
            let ids = [];
            val.map((v) => { ids.push(v.id); })
            let goods_ids = ids.join(",");
            request.post('/activity/sign/activity/goods/add', { activity_id: this.queryParams.activity_id, goods_ids: goods_ids }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false
                    this.$message.success('操作成功');
                    this.loadData();
                }
            });
        },
        // 查看大图
        showBigImg(index) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
    }
}
</script>
<style scoped>
#imFile {
    position: relative;
}

.errImg {
    height: 50px;
    width: 80px !important;
    margin-top: 10px;
    border-radius: 0 !important;
}

.el-table__body-wrapper .is-scrolling-left {
    height: 500px !important;
}

.display {
    display: flex;
    margin-top: 10px;
}

.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.del-dialog-cnt {
    font-size: 16px;
    text-align: center;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.green {
    color: green;
}

.mr10 {
    margin-right: 10px;
}

.crop-demo-btn {
    position: absolute;
    right: 0px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    float: right;
}

.crop-input {
    position: absolute;
    width: 100px;
    height: 40px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.pre-img {
    width: 100px;
    height: 100px;
    background: #f8f8f8;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-top: 10px;
}

.cell {
    text-align: center;
}

.el-input--suffix .el-input__inner {
    padding-right: 15px;
}
</style>
